import React from 'react'
import LayoutDefault from "../layouts";
import * as classes from '../sass/style.module.scss'
import { L10nProvider } from '@logdoc/l10n';

const Pi = () => {
	return (
		<L10nProvider>
			<Pipe />
		</L10nProvider>
	)
}

export const Pipe = () => {
	return (
		<LayoutDefault>
			<section className={classes.sec}>
				<div className={classes.secBreadcrumbs}>
					<span>Расширение</span>
					<span>/</span>
					<span>Pipe плагины</span>
				</div>
				<h2 className={classes.secTitle}>
					Pipe плагины
				</h2>
				<div className={classes.secDescription}>
					<p>
						Logdoc из коробки предоставляет <a href="#">4 сценария</a>, которые могут быть использованы в
						любом слушателе, в любом составе:
					</p>
					<ul>
						<li>
							<span className={classes.textOrange}>entrypipes.Emailer</span> - SMTP-отсылка письма
						</li>
						<li>
							<span className={classes.textOrange}>entrypipes.HttpCallback</span> - HTTP-callback на
							указанный пользователем http-endpoint
						</li>
						<li>
							<span className={classes.textOrange}>entrypipes.Telegramer</span> - Уведомление пользователя
							Telegram
						</li>
						<li>
							<span className={classes.textOrange}>entrypipes.WsNotifier</span> - Отправка сообщения в
							websocket инициатора
						</li>
					</ul>
				</div>
			</section>
		</LayoutDefault>
	);
};

export default Pi;